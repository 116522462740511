<template>
  <div class="min-h-full">
    <div class="bg-gray-800 pb-32">
      <Disclosure v-slot="{ open }" as="nav" class="bg-gray-800">
        <div class="container mx-auto sm:px-6 lg:px-8">
          <div class="border-b border-gray-700">
            <div class="flex h-16 items-center justify-between px-4 sm:px-0">
              <div class="flex items-center">
                <div class="shrink-0">
                  <!-- <NuxtImg class="size-8" src="/icon.png" alt="FMA" /> -->
                  <img class="size-8" src="/icon.png" alt="FMA" />
                </div>
                <div class="hidden md:block">
                  <div class="ml-10 flex items-baseline space-x-4">
                    <template v-for="(item, idx) in navigation" :key="idx">
                      <NuxtLink
                        v-if="
                          !item?.auth ||
                          (item.auth && status === 'authenticated')
                        "
                        :to="item.to"
                        class="rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                        ,
                        >{{ item.name }}</NuxtLink
                      >
                    </template>
                  </div>
                </div>
              </div>
              <div class="hidden md:block">
                <div class="ml-4 flex items-center md:ml-6">
                  <button
                    type="button"
                    class="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span class="absolute -inset-1.5" />
                    <span class="sr-only">View notifications</span>
                    <BellIcon class="size-6" aria-hidden="true" />
                  </button>

                  <!-- Profile dropdown -->
                  <Menu as="div" class="relative ml-3">
                    <div>
                      <ClientOnly>
                        <MenuButton
                          as="NuxtLink"
                          class="relative flex max-w-xs cursor-pointer items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        >
                          <span class="absolute -inset-1.5" />
                          <span class="sr-only">Open user menu</span>
                          <img
                            class="size-8 rounded-full"
                            :src="user.imageUrl"
                            alt=""
                            sizes="40px"
                          />
                          <!-- <NuxtImg
                            class="size-8 rounded-full"
                            :src="user.imageUrl"
                            alt=""
                            sizes="40px"
                          /> -->
                        </MenuButton>
                      </ClientOnly>
                    </div>
                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="absolute right-0 z-10 mt-2 w-48 origin-top-right overflow-hidden rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none"
                      >
                        <MenuItem v-if="status === 'authenticated'">
                          {{ fullName }}
                        </MenuItem>
                        <MenuItem
                          v-if="status === 'authenticated'"
                          v-slot="{ active }"
                        >
                          <div
                            :class="[
                              active ? 'bg-gray-100' : '',
                              'block w-full px-4 py-2 text-left text-sm text-gray-700',
                            ]"
                            @click="signOut()"
                          >
                            Sign Out
                          </div>
                        </MenuItem>
                        <MenuItem v-else v-slot="{ active }">
                          <div
                            :class="[
                              active ? 'bg-gray-100' : '',
                              'block w-full px-4 py-2 text-left text-sm text-gray-700',
                            ]"
                            @click="signIn('sso')"
                          >
                            Sign In
                          </div>
                        </MenuItem>
                      </MenuItems>
                    </transition>
                  </Menu>
                </div>
              </div>
              <div class="-mr-2 flex md:hidden">
                <!-- Mobile menu button -->
                <ClientOnly>
                  <DisclosureButton
                    class="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span class="absolute -inset-0.5" />
                    <span class="sr-only">Open main menu</span>
                    <Bars3Icon
                      v-if="!open"
                      class="block size-6"
                      aria-hidden="true"
                    />
                    <XMarkIcon v-else class="block size-6" aria-hidden="true" />
                  </DisclosureButton>
                </ClientOnly>
              </div>
            </div>
          </div>
        </div>

        <DisclosurePanel class="border-b border-gray-700 md:hidden">
          <div class="space-y-1 px-2 py-3 sm:px-3">
            <template v-for="(item, idx) in navigation" :key="idx">
              <DisclosureButton
                v-if="!item?.auth || (item.auth && status === 'authenticated')"
                :key="item.name"
                as="NuxtLink"
                :to="item.to"
                class="block rounded-md px-3 py-2 font-medium text-white"
                >{{ item.name }}</DisclosureButton
              >
            </template>
          </div>
          <div class="border-t border-gray-700 pb-3 pt-4">
            <div class="flex items-center px-5">
              <div class="shrink-0">
                <!-- <img class="size-10 rounded-full" :src="user.imageUrl" alt="" /> -->
                <NuxtImg
                  class="size-10 rounded-full"
                  :src="user.imageUrl"
                  alt=""
                />
              </div>
              <div class="ml-3">
                <div class="text-base font-medium leading-none text-white">
                  {{ fullName }}
                </div>
                <div class="text-sm font-medium leading-none text-gray-400">
                  {{ user.email }}
                </div>
              </div>
              <button
                type="button"
                class="relative ml-auto shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              >
                <span class="absolute -inset-1.5" />
                <span class="sr-only">View notifications</span>
                <BellIcon class="size-6" aria-hidden="true" />
              </button>
            </div>
            <div class="mt-3 space-y-1 px-2">
              <DisclosureButton
                v-if="status === 'authenticated'"
                as="div"
                class="block rounded-md px-3 py-2 text-base font-medium text-gray-100 hover:bg-gray-300 hover:text-white"
                @click="signOut"
                >Sign Out</DisclosureButton
              >
              <DisclosureButton
                v-else
                as="div"
                class="block rounded-md px-3 py-2 text-base font-medium text-gray-100 hover:bg-gray-300 hover:text-white"
                @click="signIn('sso')"
                >Sign In</DisclosureButton
              >
            </div>
          </div>
        </DisclosurePanel>
      </Disclosure>
      <header class="py-10">
        <div class="container mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold tracking-tight text-white">
            {{ pageTitle }}
          </h1>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <div class="container mx-auto px-4 pb-12 sm:px-6 lg:px-8">
        <div class="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
          <slot />
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { signIn } from "#auth";
import { computed, storeToRefs, useAuth, useHeadSafe } from "#imports";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useHeaderStore } from "~/stores/Header";

const { session, status, signOut } = useAuth();

const fullName = computed(
  () =>
    `${session.value?.user?.givenName ?? ""} ${
      session.value?.user?.familyName ?? ""
    }`.trim() || "Unknown Name"
);

const user = computed(() => {
  if (status.value === "authenticated") {
    return {
      name: session.value?.user?.name || "Unknown User",
      email: session.value?.user?.email,
      imageUrl: "/user.jpeg",
    };
  }

  return {
    name: "Unathenticated User",
    email: "",
    imageUrl: "/user.jpeg",
  };
});
const navigation = [
  { name: "Dashboard", to: "/", auth: false },
  { name: "Findings", to: "/findings", auth: true },
  { name: "Customers", to: "/customers", auth: true },
];
// const userNavigation:any[] = [
//   // { label: "Example", to: "#" },
// ];
const { pageTitle } = storeToRefs(useHeaderStore());

useHeadSafe({
  title: computed(() => pageTitle.value),
});
</script>
<style>
html {
  @apply h-full bg-gray-100;
}
body,
#__nuxt {
  @apply min-h-full h-auto;
}
</style>
